<template>
  <header class="navbar">
    <div class="header">
      <router-link :to="{ name: 'home-' + $route.meta.lang }">
        <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/pinestone-logo-fr.png" alt="Logo PineStone" class="logo" />
        <img v-else src="@/assets/img/pinestone-logo-en.png" alt="Logo PineStone" class="logo" />
      </router-link>

      <div class="right">
        <router-link v-if="$route.meta.lang === 'en'" :to="{ name: $route.name.replace('en', 'fr') }" class="lang">FR</router-link>
        <router-link v-if="$route.meta.lang === 'fr'" :to="{ name: $route.name.replace('fr', 'en') }" class="lang">EN</router-link>

        <div class="burger" @click.stop.prevent="showMobileMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div class="mobile_menu" :class="{ show: menuIsOpen }" @click.stop="showMobileMenu">
      <div class="menu">
        <div class="top">
          <router-link v-if="$route.meta.lang === 'en'" :to="{ name: $route.name.replace('en', 'fr') }" class="lang">FR</router-link>
          <router-link v-if="$route.meta.lang === 'fr'" :to="{ name: $route.name.replace('fr', 'en') }" class="lang">EN</router-link>

          <img src="@/assets/img/close.svg" class="close" alt="Close" @click.stop.prevent="showMobileMenu" />
        </div>
        <nav>
          <router-link :to="{ name: 'home-' + $route.meta.lang }" :class="{ active: $route && $route.name.includes('home') }">{{
            $t('menu-1')
          }}</router-link>
          <router-link :to="{ name: 'about-' + $route.meta.lang }" :class="{ active: $route && $route.name.includes('about') }">{{
            $t('menu-2')
          }}</router-link>
          <router-link :to="{ name: 'team-' + $route.meta.lang }" :class="{ active: $route && $route.name.includes('team') }">{{
            $t('Team')
          }}</router-link>
          <router-link :to="{ name: 'strategies-' + $route.meta.lang }" :class="{ active: $route && $route.name.includes('strategies') }">{{
            $t('menu-3')
          }}</router-link>
          <router-link :to="{ name: 'sustainability-' + $route.meta.lang }" :class="{ active: $route && $route.name.includes('sustainability') }">{{
            $t('Sustainability')
          }}</router-link>
          <router-link :to="{ name: 'contact-' + $route.meta.lang }" :class="{ active: $route && $route.name.includes('contact') }">{{
            $t('menu-4')
          }}</router-link>
        </nav>
      </div>
      <div class="overlay" @click.stop.prevent="showMobileMenu"></div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      menuIsOpen: false
    }
  },
  methods: {
    showMobileMenu() {
      if (this.menuIsOpen) {
        this.menuIsOpen = false
      } else {
        this.menuIsOpen = true
      }
    }
  },
  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
