<template>
  <div class="page-wrapper">
    <section class="hero_block politique">
      <h1 class="title small" data-inview="fadeInUp" data-delay="200">PineStone</h1>
      <h2 class="title" data-inview="fadeInUp" data-delay="300" v-html="$t('privacy-title')"></h2>
      <div class="line"></div>
      <div class="overlay"></div>
    </section>

    <section class="content_wrap">
      <div class="content">
        <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
          <h3 class="title medium">{{ $t('chief-compliance') }}</h3>
        </div>
        <div class="data" data-inview="fadeInUp" data-delay="300">
          <p class="regular-text"> {{ $t('chief-compliance-text') }} </p>
          <br>
          <h4 class="title small">{{ $t('chief-compliance-sub-title') }}</h4>
          <p class="regular-text" v-html="$t('chief-compliance-sub-text')"></p>
        </div>
      </div>
    </section>

    <section class="stats_wrap">
      <div class="stat empty" data-inview="fadeInUp" data-delay="200"></div>
      
      <div v-if="$route.meta.lang == 'en'" class="stat larger" data-inview="fadeInUp" data-delay="300">
        
        <p class="big mh invert">
          <span class="bottom">Privacy</span>Policy
        </p>

        <div class="table">
          <a href="/pdf/privacy-policy.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Privacy Policy</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
        </div>

      </div>

      <div v-if="$route.meta.lang == 'fr'" class="stat larger" data-inview="fadeInUp" data-delay="300">
        
        <p class="big mh invert">
          <span class="bottom">Politique de</span>confidentialité
        </p>

        <div class="table">
          <a href="/pdf/politique-de-confidentialite.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Déclaration en matière de protection des données</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
        </div>

      </div>

      <div class="stat empty" data-inview="fadeInUp" data-delay="400"></div>
    </section>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: this.$t(`PineStone Sustainability`)
      },
      meta: [
        {
          name: 'description',
          content: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          ),
          id: 'desc'
        },
        { name: 'application-name', content: this.$t(`PineStone Sustainability`) },
        {
          name: 'twitter:title',
          content: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          )
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          )
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`PineStone Sustainability`) },
        { property: 'og:site_name', content: this.$t(`PineStone Sustainability`) },
        {
          property: 'og:description',
          content: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          )
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://pinestoneam.com/img/pinestone-og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://pinestoneam.com/img/pinestone-og.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
