<template>
  <div class="page-wrapper">
    <section class="hero_block nadim">
      <h1 class="title small" data-inview="fadeInUp" data-delay="200">{{ $t('nadim-hero-1') }}</h1>
      <h2 class="title" data-inview="fadeInUp" data-delay="300">{{ $t('nadim-hero-2') }}</h2>
      <div class="line"></div>
      <div class="overlay"></div>
    </section>

    <section class="founder">
      <div class="wrap">
        <div class="img" data-inview="slideRight" data-delay="200">
          <img src="@/assets/img/nadim-rizk-2.jpg" alt="Nadim Rizk" />
        </div>
        <div class="data">
          <h3 class="title small" data-inview="fadeInUp" data-delay="200">{{ $t('nadim-title') }}</h3>
          <p class="regular-text" data-inview="fadeInUp" data-delay="300" v-html="$t('nadim-desc')"></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: this.$t(`meta-title-nadim`)
      },
      meta: [
        {
          name: 'description',
          content: this.$t(`meta-desc`),
          id: 'desc'
        },
        { name: 'application-name', content: this.$t(`meta-title-nadim`) },
        { name: 'twitter:title', content: this.$t(`meta-desc`) },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t(`meta-desc`)
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`meta-title-nadim`) },
        { property: 'og:site_name', content: this.$t(`meta-title-nadim`) },
        {
          property: 'og:description',
          content: this.$t(`meta-desc`)
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://pinestoneam.com/img/pinestone-og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://pinestoneam.com/img/pinestone-og.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
