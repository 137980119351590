<template>
  <div class="page-wrapper">
    <section class="hero_block about">
      <h1 class="title small" data-inview="fadeInUp" data-delay="200">{{ $t('about-hero-1') }}</h1>
      <h2 class="title" data-inview="fadeInUp" data-delay="300">{{ $t('about-hero-2') }}</h2>
      <div class="line"></div>
      <div class="overlay"></div>
    </section>

    <section class="content_wrap">
      <div class="content">
        <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
          <h3 class="title medium" v-html="$t('about-content-title-1')"></h3>
        </div>
        <div class="data" data-inview="fadeInUp" data-delay="300" v-html="$t('about-content-desc-1')"></div>
      </div>
      <div class="content">
        <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
          <h3 class="title medium">{{ $t('about-content-title-2') }}</h3>
        </div>
        <div class="data" data-inview="fadeInUp" data-delay="300" v-html="$t('about-content-desc-2')"></div>
      </div>
    </section>

    <section class="stats_wrap">
      <div class="stat" data-inview="fadeInUp" data-delay="200" v-html="$t('about-stat-1')"></div>
      <div class="stat" data-inview="fadeInUp" data-delay="300" v-html="$t('about-stat-2')"></div>
      <div class="stat" data-inview="fadeInUp" data-delay="400" v-html="$t('about-stat-3')"></div>
    </section>

    <section class="img_text switch">
      <div class="data">
        <!-- <h2 class="title medium" data-inview="fadeInUp" data-delay="200" v-html="$t('about-content-title-3')"></h2> -->
        <!-- <div class="line" data-inview="fadeInUp" data-delay="300"></div> -->
        <!-- <h3 class="title small" data-inview="fadeInUp" data-delay="400">{{ $t('about-content-title-4') }}</h3> -->
        <p class="regular-text" data-inview="fadeInUp" data-delay="500" v-html="$t('about-content-desc-3')"></p>
      </div>
      <div class="img" data-inview="slideRight" data-delay="200">
        <img src="@/assets/img/stone-pine.jpg" alt="PineStone" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: this.$t(`meta-title-about`)
      },
      meta: [
        {
          name: 'description',
          content: this.$t(`meta-desc`),
          id: 'desc'
        },
        { name: 'application-name', content: this.$t(`meta-title-about`) },
        { name: 'twitter:title', content: this.$t(`meta-desc`) },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t(`meta-desc`)
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`meta-title-about`) },
        { property: 'og:site_name', content: this.$t(`meta-title-about`) },
        {
          property: 'og:description',
          content: this.$t(`meta-desc`)
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://pinestoneam.com/img/pinestone-og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://pinestoneam.com/img/pinestone-og.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
