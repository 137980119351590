<template>
  <div class="page-wrapper">
    <div v-if="$route.query.success" class="success-message">
      <p v-if="$route.meta.lang === 'fr'">
        Merci, Votre message a bien été envoyé.
      </p>
      <p v-if="$route.meta.lang === 'en'">
        Thank you, Your message has been sent.
      </p>
    </div>

    <section class="hero_home">
      <h1 class="title small" data-inview="fadeInUp" data-delay="200">{{ $t('hero-title-1') }}</h1>
      <h2 class="title" data-inview="fadeInUp" data-delay="300">{{ $t('hero-title-2') }}</h2>
      <a v-scroll-to:0 href="#about" class="cta" data-inview="fadeInUp" data-delay="400">{{ $t('more') }}</a>
      <div class="line"></div>
      <div class="gradient"></div>
    </section>

    <section id="about" class="img_text">
      <div class="data">
        <h2 class="title medium" data-inview="fadeInUp" data-delay="200">{{ $t('about-title-1') }}</h2>
        <div class="line" data-inview="fadeInUp" data-delay="300"></div>
        <h3 class="title small" data-inview="fadeInUp" data-delay="400" v-html="$t('about-title-2')"></h3>
        <p class="regular-text" data-inview="fadeInUp" data-delay="500">{{ $t('about-desc') }}</p>
        <router-link :to="{ name: 'about-' + $route.meta.lang }" class="cta dark" data-inview="fadeInUp" data-delay="600">{{ $t('more') }}</router-link>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <img src="@/assets/img/tree.svg" alt="PineStone" />
      </div>
    </section>

    <section class="stats_wrap">
      <div class="stat" data-inview="fadeInUp" data-delay="200" v-html="$t('home-stat-1')"></div>
      <div class="stat" data-inview="fadeInUp" data-delay="300" v-html="$t('home-stat-2')"></div>
      <div class="stat" data-inview="fadeInUp" data-delay="400" v-html="$t('home-stat-3')"></div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: this.$t(`meta-title-home`)
      },
      meta: [
        {
          name: 'description',
          content: this.$t(`meta-desc`),
          id: 'desc'
        },
        { name: 'application-name', content: this.$t(`meta-title-home`) },
        { name: 'twitter:title', content: this.$t(`meta-desc`) },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t(`meta-desc`)
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`meta-title-home`) },
        { property: 'og:site_name', content: this.$t(`meta-title-home`) },
        {
          property: 'og:description',
          content: this.$t(`meta-desc`)
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://pinestoneam.com/img/pinestone-og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://pinestoneam.com/img/pinestone-og.jpg'
        }
      ]
    }
  },
  mounted() {
    if (document.querySelector('.success-message')) {
      setTimeout(() => {
        document.querySelector('.success-message').classList.add('fade')
      }, 7999)
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
