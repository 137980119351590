<template>
  <div class="page-wrapper">
    <section class="hero_block team">
      <h1 class="title small" data-inview="fadeInUp" data-delay="200">{{ $t('team-hero-1') }}</h1>
      <h2 class="title" data-inview="fadeInUp" data-delay="300">{{ $t('team-hero-2') }}</h2>
      <div class="line"></div>
      <div class="overlay"></div>
    </section>

    <section class="content_wrap white-background">
      <div class="team-list">
        <h3 class="title medium" data-inview="fadeInUp" data-delay="200">{{ $t('team-title-1') }}</h3>
        <div v-for="(item, i) in $t('Team1')" :key="i" class="player" :class="item.split" data-inview="fadeInUp" data-delay="200">
          <div class="img">
            <img :src="item.img" :alt="item.name" :title="item.name" />
          </div>
          <h4 class="title tiny" v-html="item.name"></h4>
          <p class="regular-text" v-html="item.poste"></p>
          <a href="#" class="bio-link" @click.stop.prevent="showBio(item)">{{ $t('bio') }}</a>
        </div>
      </div>

      <div class="team-list">
        <h3 class="title medium" data-inview="fadeInUp" data-delay="200">{{ $t('team-title-2') }}</h3>
        <div v-for="(item, i) in $t('Team2')" :key="i" class="player" data-inview="fadeInUp" data-delay="200">
          <div class="img">
            <img :src="item.img" :alt="item.name" :title="item.name" />
          </div>
          <h4 class="title tiny" v-html="item.name"></h4>
          <p class="regular-text" v-html="item.poste"></p>
          <a href="#" class="bio-link" @click.stop.prevent="showBio(item)">{{ $t('bio') }}</a>
        </div>
      </div>

      <div class="team-list">
        <h3 class="title medium" data-inview="fadeInUp" data-delay="200">{{ $t('team-title-3') }}</h3>
        <div v-for="(item, i) in $t('Team3')" :key="i" class="player" data-inview="fadeInUp" data-delay="200">
          <div class="img">
            <img :src="item.img" :alt="item.name" :title="item.name" />
          </div>
          <h4 class="title tiny" v-html="item.name"></h4>
          <p class="regular-text" v-html="item.poste"></p>
          <a href="#" class="bio-link" @click.stop.prevent="showBio(item)">{{ $t('bio') }}</a>
        </div>
      </div>
    </section>

    <div class="biography-wrap" :class="{ show: teamBioOpen }">
      <div v-if="teamBioOpen" class="pop">
        <header class="player-name">
          <div class="img">
            <img :src="teamBioOpen.imgsmall" :alt="teamBioOpen.name" />
          </div>
          <div class="info">
            <h4 class="title tiny" v-html="teamBioOpen.name"></h4>
            <p class="regular-text">{{ teamBioOpen.poste }}</p>
          </div>
        </header>
        <p class="regular-text" v-html="teamBioOpen.biography"></p>
        <a href="#" class="close" @click.stop.prevent="showBio(null)">
          <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.5 8.3855L4.40501 0.290509C4.01767 -0.0968362 3.38823 -0.0968362 3.00089 0.290509L0.290508 3.00089C-0.0968362 3.38823 -0.0968362 4.01767 0.290508 4.40501L8.3855 12.5L0.290508 20.595C-0.0968362 20.9823 -0.0968362 21.6118 0.290508 21.9991L3.00089 24.7095C3.38823 25.0968 4.01767 25.0968 4.40501 24.7095L12.5 16.6145L20.595 24.7095C20.9823 25.0968 21.6118 25.0968 21.9991 24.7095L24.7095 21.9991C25.0968 21.6118 25.0968 20.9823 24.7095 20.595L16.6145 12.5L24.7095 4.40501C25.0968 4.01767 25.0968 3.38823 24.7095 3.00089L21.9991 0.290509C21.6118 -0.0968362 20.9823 -0.0968362 20.595 0.290509L12.5 8.3855Z"
              fill="#353F4E"
            />
          </svg>
        </a>
      </div>
      <div class="overlay" @click.stop.prevent="showBio(null)"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: this.$t(`meta-title-strategies`)
      },
      meta: [
        {
          name: 'description',
          content: this.$t(`meta-desc`),
          id: 'desc'
        },
        { name: 'application-name', content: this.$t(`meta-title-strategies`) },
        { name: 'twitter:title', content: this.$t(`meta-desc`) },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t(`meta-desc`)
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`meta-title-strategies`) },
        { property: 'og:site_name', content: this.$t(`meta-title-strategies`) },
        {
          property: 'og:description',
          content: this.$t(`meta-desc`)
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://pinestoneam.com/img/pinestone-og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://pinestoneam.com/img/pinestone-og.jpg'
        }
      ]
    }
  },
  data() {
    return {
      teamBioOpen: null
    }
  },
  methods: {
    showBio(bio) {
      if (this.teamBioOpen) {
        this.teamBioOpen = null
      } else {
        this.teamBioOpen = bio
      }
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
