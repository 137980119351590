const initInViewAnimations = () => {
  setTimeout(() => {
    // In View Observer trigger Anims
    const animSelector = '[data-inview]'
    const animElements = [].slice.call(document.querySelectorAll(animSelector))

    const animObserver = new IntersectionObserver(function(elems) {
      elems.forEach(function(elem) {
        if (elem.intersectionRatio > 0) {
          elem.target.classList.add('is-in-view')
        }
      })
    })

    setTimeout(function() {
      animElements.forEach(function(elem) {
        animObserver.observe(elem)
      })
    }, 15)
  }, 15)
}

export default initInViewAnimations
