<template>
  <footer class="footer">
    <div v-if="$route.meta.lang === 'en'" class="left">
      <!-- <a href="#">{{ $t('footer-1') }}</a>-->
      
      <a href="/privacy-notice">{{ $t('footer-2') }}</a>

      <a href="./pdf/terms-of-use.pdf" target="_blank">{{ $t('footer-3') }}</a>
      <router-link :to="{ name: 'sustainability-' + $route.meta.lang }" :class="{ active: $route && $route.name.includes('sustainability') }">{{
        $t('Sustainability')
      }}</router-link>
    </div>
    <div v-if="$route.meta.lang === 'fr'" class="left">
      <a href="/fr/declaration-en-matiere-de-protection-des-donnees">{{ $t('footer-2') }}</a>
      <a href="/pdf/avis-juridique.pdf" target="_blank">{{ $t('footer-3') }}</a>
      <router-link :to="{ name: 'sustainability-' + $route.meta.lang }" :class="{ active: $route && $route.name.includes('sustainability') }">{{
        $t('Sustainability')
      }}</router-link>
    </div>
    <a v-if="$route.meta.lang === 'fr'" href="/fr/contact" class="cta dark">{{ $t('contact-hero-1') }}</a>
    <a v-if="$route.meta.lang === 'en'" href="/contact" class="cta dark">{{ $t('contact-hero-1') }}</a>
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
