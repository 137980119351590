import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import About from '@/views/About'
import Strategies from '@/views/Strategies'
import Sustainability from '@/views/Sustainability'
import Nadim from '@/views/Nadim'
import Contact from '@/views/Contact'
import Team from '@/views/Team'
import Privacy from '@/views/Privacy-notice'
import Policies from '@/views/Policies'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home-en',
      component: Home,
      meta: {
        lang: 'en'
      }
    },
    {
      path: '/fr',
      name: 'home-fr',
      component: Home,
      meta: {
        lang: 'fr'
      }
    },
    {
      path: '/about',
      name: 'about-en',
      component: About,
      meta: {
        lang: 'en'
      }
    },
    {
      path: '/fr/a-propos',
      name: 'about-fr',
      component: About,
      meta: {
        lang: 'fr'
      }
    },
    {
      path: '/strategies',
      name: 'strategies-en',
      component: Strategies,
      meta: {
        lang: 'en'
      }
    },
    {
      path: '/fr/strategies',
      name: 'strategies-fr',
      component: Strategies,
      meta: {
        lang: 'fr'
      }
    },
    {
      path: '/team',
      name: 'team-en',
      component: Team,
      meta: {
        lang: 'en'
      }
    },
    {
      path: '/fr/equipe',
      name: 'team-fr',
      component: Team,
      meta: {
        lang: 'fr'
      }
    },
    {
      path: '/sustainability',
      name: 'sustainability-en',
      component: Sustainability,
      meta: {
        lang: 'en'
      }
    },
    {
      path: '/fr/durabilite',
      name: 'sustainability-fr',
      component: Sustainability,
      meta: {
        lang: 'fr'
      }
    },
    {
      path: '/nadim-rizk',
      name: 'nadim-en',
      component: Nadim,
      meta: {
        lang: 'en'
      }
    },
    {
      path: '/fr/nadim-rizk',
      name: 'nadim-fr',
      component: Nadim,
      meta: {
        lang: 'fr'
      }
    },
    {
      path: '/contact',
      name: 'contact-en',
      component: Contact,
      meta: {
        lang: 'en'
      }
    },
    {
      path: '/fr/contact',
      name: 'contact-fr',
      component: Contact,
      meta: {
        lang: 'fr'
      }
    },
    {
      path: '/privacy-notice',
      name: 'privacy-en',
      component: Privacy,
      meta: {
        lang: 'en'
      }
    },
    {
      path: '/fr/declaration-en-matiere-de-protection-des-donnees',
      name: 'privacy-fr',
      component: Privacy,
      meta: {
        lang: 'fr'
      }
    },
    {
      path: '/policies',
      name: 'policies-en',
      component: Policies,
      meta: {
        lang: 'en'
      }
    },
    {
      path: '/fr/politiques',
      name: 'policies-fr',
      component: Policies,
      meta: {
        lang: 'fr'
      }
    },
    { path: '*', redirect: '/' }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
