<template>
  <div class="page-wrapper">
    <section class="hero_block sustainability">
      <h1 class="title small" data-inview="fadeInUp" data-delay="200">PineStone</h1>
      <h2 class="title" data-inview="fadeInUp" data-delay="300">{{ $t('Sustainability') }}</h2>
      <div class="line"></div>
      <div class="overlay"></div>
    </section>

    <section class="content_wrap">
      <div class="content">
        <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
          <h3 class="title medium">{{ $t('Sustainability') }}</h3>
        </div>
        <div class="data" data-inview="fadeInUp" data-delay="300">
          <h4 class="title small">{{ $t('As long-term investors') }}</h4>
          <p class="regular-text">
            {{
              $t(
                'PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company. We utilize our long tenure as shareholders to engage with our portfolio companies and advocate for what we believe to be in the best interests of minority shareholders. As prudent stewards of our client’s capital, we believe it is our obligation to undertake this analysis. As investors alongside our clients, and engaged citizens, we believe it is best for long-term returns and society.'
              )
            }}
          </p>
        </div>
      </div>
    </section>

    <section class="stats_wrap">
      <div class="stat empty" data-inview="fadeInUp" data-delay="200"></div>
      <div class="stat larger" data-inview="fadeInUp" data-delay="300">
        <p class="big mh invert">
          <span class="bottom">{{ $t('Sustainability') }}</span> {{ $t('Policies') }}
        </p>

        <p class="big invert sup">
          <span class="bottom">{{ $t('Firmwide Pinestone Sustainability Policies') }}</span>
        </p>
        <div v-if="$route.meta.lang == 'en'" class="table">
          <a href="/pdf/ri-policy/pinestone-responsible-investment-policy-202208.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>{{ $t('Responsible Investment Policy') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
          <a href="/pdf/proxy/pinestone-proxy-voting-guidelines-website-2022.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>{{ $t('Proxy Voting Guidelines') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/diversity-equity-and-inclusion-policy.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Diversity, Equity and Inclusion Policy</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <p class="big mt invert">
            <span class="bottom">{{ $t('SFDR Article 8 Funds Sustainability Policies') }}</span>
          </p>
          <a
            href="/pdf/sustainability-risk/pinestone-sustainability-risk-policy202210.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="row file"
          >
            <div class="cell color">
              <p>{{ $t('Sustainability Risk Policy') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
          <a href="/pdf/pai/pinestone-pai-statement-202210.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>{{ $t('Principle Adverse Impacts Statement') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
          <a href="/pdf/sustainalbility/sustainability-related-disclosure.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>{{ $t('Sustainability Related Disclosure') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
          <a href="/pdf/pinestone-remuneration-policy-2023.07.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>{{ $t('Remuneration Policy') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
        </div>

        <div v-if="$route.meta.lang == 'fr'" class="table">
          <a
            href="/pdf/ri-policy/pinestone-politique-dinvestissement-responsable-202208.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="row file"
          >
            <div class="cell color">
              <p>{{ $t('Responsible Investment Policy') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
          <a href="/pdf/proxy/pinestone-vote-par-procuration-site-web-2022-fr.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>{{ $t('Proxy Voting Guidelines') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
          <a href="/pdf/politique-sur-la-diversite-l-equite-et-l-inclusion.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Politique sur la diversité, l’équité et l’inclusion</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <p class="big mt invert">
            <span class="bottom">{{ $t('SFDR Article 8 Funds Sustainability Policies') }}</span>
          </p>

          <a
            href="/pdf/sustainability-risk/pinestone-politique-de-risque-en-matiere-de-durabilite-fr.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="row file"
          >
            <div class="cell color">
              <p>{{ $t('Sustainability Risk Policy') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
          <a href="/pdf/pai/pinestone-declaration-pai-fr-202210.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>{{ $t('Principle Adverse Impacts Statement') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
          <a
            href="/pdf/sustainalbility/divulgations-liees-au-developpement-durable-fr.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="row file"
          >
            <div class="cell color">
              <p>{{ $t('Divulgations liées au développement durable') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
          <a href="/pdf/politique-de-remuneration2023.07.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>{{ $t('Remuneration Policy') }}</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
        </div>
      </div>
      <div class="stat empty" data-inview="fadeInUp" data-delay="400"></div>
    </section>

    <section class="content_wrap">
      <div class="content">
        <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
          <h3 class="title medium">{{ $t('Collaborations') }}</h3>
        </div>
        <div class="data" data-inview="fadeInUp" data-delay="300">
          <h4 class="title small">{{ $t('Collaborations') }}</h4>
          <p class="regular-text">
            {{
              $t(
                'By working with select organizations that help us stay current on different and evolving perspectives on sustainability issues. We apply what we learn to our investment research process and into productive engagement with companies that we invest in.'
              )
            }}
          </p>
          <div class="logos">
            <img src="@/assets/img/IFRS-Alliance-logo.jpg" alt="IFRS-Alliance-logo" />
            <img src="@/assets/img/pri-sig-web-logo.png" alt="pri-sig-web-logo" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: this.$t(`PineStone Sustainability`)
      },
      meta: [
        {
          name: 'description',
          content: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          ),
          id: 'desc'
        },
        { name: 'application-name', content: this.$t(`PineStone Sustainability`) },
        {
          name: 'twitter:title',
          content: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          )
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          )
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`PineStone Sustainability`) },
        { property: 'og:site_name', content: this.$t(`PineStone Sustainability`) },
        {
          property: 'og:description',
          content: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          )
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://pinestoneam.com/img/pinestone-og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://pinestoneam.com/img/pinestone-og.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
