<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import { isNil } from 'lodash'
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'

// Intro Animation Scripts
import initInViewAnimations from '@/misc/animations-layer'

export default {
  components: { NavBar, FooterBar },

  watch: {
    $route: {
      handler($route) {
        const fullUrl = window.location.href

        if (fullUrl.includes('stonepine')) {
          // Change stonepine for pinestone
          const newUrl = fullUrl.replace('stonepine', 'pinestone')
          // Redirect to new url
          window.location.href = newUrl
        }

        if (!isNil($route)) {
          if ($route.meta.lang === 'en') {
            this.$i18n.locale = 'en'
          } else {
            this.$i18n.locale = 'fr'
          }
        }

        setTimeout(() => {
          initInViewAnimations()
        }, 755)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/typos.scss';
@import '@/theme/header.scss';
@import '@/theme/footer.scss';
@import '@/theme/site.scss';
@import '@/theme/animations-layer.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: $color3;
  font-family: sofia-pro, sans-serif;
}

img {
  display: block;
  height: auto;
  width: 100%;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}
</style>
