<template>
  <div class="page-wrapper">
    <div v-if="$route.query.success" class="success-message">
      <p v-if="$route.meta.lang === 'fr'">
        Merci, Votre message a bien été envoyé.
      </p>
      <p v-if="$route.meta.lang === 'en'">
        Thank you, Your message has been sent.
      </p>
    </div>

    <section class="hero_block contact">
      <h1 class="title" data-inview="fadeInUp" data-delay="200">{{ $t('contact-hero-1') }}</h1>
      <div class="line"></div>
      <div class="overlay"></div>
    </section>

    <section class="contacts">
      <a
        href="https://www.google.com/maps/dir//1981+Av.+McGill+College+%231600,+Montr%C3%A9al,+QC+H3A+0G6/@45.5033659,-73.5750774,17z/data=!4m17!1m7!3m6!1s0x4cc91a4690f7b2f1:0x6fce0a6551527023!2s1981+Av.+McGill+College+%231600,+Montr%C3%A9al,+QC+H3A+0G6!3b1!8m2!3d45.5033659!4d-73.5728887!4m8!1m0!1m5!1m1!1s0x4cc91a4690f7b2f1:0x6fce0a6551527023!2m2!1d-73.5728887!2d45.5033659!3e3"
        target="_blank"
      >
        <address class="link" data-inview="fadeInUp" data-delay="200" v-html="$t('contact-address')"></address>
      </a>
      <div class="bloc" data-inview="fadeInUp" data-delay="300">
        <a href="tel:4387930444" class="link">{{ $t('contact-phone') }}</a>
        <a href="mailto:info@pinestoneam.com" class="link">{{ $t('contact-email') }}</a>
      </div>
      <a href="mailto:info@pinestoneam.com" class="cta dark" data-inview="fadeInUp" data-delay="400">{{ $t('contact-cta-email') }}</a>
    </section>

    <section class="form_wrap">
      <h3 class="title medium">{{ $t('form-title') }}</h3>

      <form action="/contact?success=1" name="Contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="Contact" />

        <input name="First and last name" type="text" class="input" :placeholder="$t('form-name')" required />
        <input name="E-mail" type="email" class="input" :placeholder="$t('form-mail')" required />
        <input name="Phone number" type="text" class="input" :placeholder="$t('form-phone')" required />
        <input name="Organization name" type="text" class="input" :placeholder="$t('form-business')" required />

        <select name="Which describes you best?" class="input select" required>
          <option value="">{{ $t('form-investisseur') }}</option>
          <option value="Institutional Investor">{{ $t('form-investisseur-option-1') }}</option>
          <option value="Investment Consultant">{{ $t('form-investisseur-option-2') }}</option>
          <option value="Other">{{ $t('form-investisseur-option-3') }}</option>
        </select>

        <select name="Strategy interest?" class="input select" required>
          <option value="">{{ $t('form-interet') }}</option>
          <option value="Global Equity">{{ $t('form-interet-option-1') }}</option>
          <option value="Internationnal Equity">{{ $t('form-interet-option-2') }}</option>
          <option value="US Equity">{{ $t('form-interet-option-3') }}</option>
        </select>

        <textarea name="message" placeholder="Message" class="input textarea"></textarea>

        <input type="submit" :value="$t('form-send')" class="submit" />
      </form>
    </section>

    <section class="map">
      <div id="map"></div>
    </section>
    <img id="map-pointer" src="@/assets/img/pin-map.svg" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: this.$t(`meta-title-contact`)
      },
      meta: [
        {
          name: 'description',
          content: this.$t(`meta-desc`),
          id: 'desc'
        },
        { name: 'application-name', content: this.$t(`meta-title-contact`) },
        { name: 'twitter:title', content: this.$t(`meta-desc`) },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t(`meta-desc`)
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`meta-title-contact`) },
        { property: 'og:site_name', content: this.$t(`meta-title-contact`) },
        {
          property: 'og:description',
          content: this.$t(`meta-desc`)
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://pinestoneam.com/img/pinestone-og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://pinestoneam.com/img/pinestone-og.jpg'
        }
      ]
    }
  },

  data() {
    return {
      mapMarker: null
    }
  },

  mounted() {
    if (document.querySelector('.success-message')) {
      setTimeout(() => {
        document.querySelector('.success-message').classList.add('fade')
      }, 5999)
    }

    setTimeout(() => {
      const scriptId = 'mapInitEl'
      const mapAlreadyAttached = !!document.getElementById(scriptId)

      if (mapAlreadyAttached) {
        if (window.google) {
          this.initGoogleMap()
        }
      } else {
        window.mapApiInitialized = this.initGoogleMap

        const script = document.createElement('script')
        script.id = scriptId
        script.src =
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyA_iUWeCzI-ONlOIDfx18r3oVDKWYWhZDU&libraries=places,geometry&callback=mapApiInitialized'
        document.body.appendChild(script)
      }
    }, 1275)
  },

  computed: mapState('app', ['appTitle']),

  methods: {
    initGoogleMap() {
      /* global google */
      const mapOptions = {
        // How zoomed in you want the map to start at (always required)
        zoom: 15,

        // The latitude and longitude to center the map (always required)
        center: new google.maps.LatLng(45.50244, -73.57225),

        // How you would like to style the map.
        // This is where you would paste any style found on Snazzy Maps.
        styles: [
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#e9e9e9' }, { lightness: 17 }]
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [{ color: '#f5f5f5' }, { lightness: 20 }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [{ color: '#ffffff' }, { lightness: 17 }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }]
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }, { lightness: 18 }]
          },
          {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }, { lightness: 16 }]
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#f5f5f5' }, { lightness: 21 }]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#dedede' }, { lightness: 21 }]
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }]
          },
          {
            elementType: 'labels.text.fill',
            stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }]
          },
          { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#f2f2f2' }, { lightness: 19 }]
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.fill',
            stylers: [{ color: '#fefefe' }, { lightness: 20 }]
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }]
          }
        ]
      }

      // Get the HTML DOM element that will contain your map
      // We are using a div with id="map" seen below in the <body>
      const mapElement = document.getElementById('map')

      // Create the Google Map using our element and options defined above
      const map = new google.maps.Map(mapElement, mapOptions)

      // Let's also add a marker while we're at it
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(45.50244, -73.57225),
        map,
        title: 'PineStone',
        icon: '/img/pin-map.svg'
      })

      this.mapMarker = marker
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

#map-pointer {
  opacity: 0;
  position: absolute;
  top: -5555px;
  left: -5555px;
}

#map {
  width: 100%;
  height: 100%;
}
</style>
